<template>
    <b-card title="Edit Campaign">
        <b-col cols="12">
            <label>Name</label>
            <b-form-input v-model="form.name" placeholder="Enter campaign name"></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Status</label>
            <b-form-select
                v-model="form.status"
                placeholder="----- Select Status -----"
                :options="statusChoice">
            </b-form-select>
        </b-col>
        
        <b-col cols="12">
            <label>Message</label>
            <b-form-textarea
                v-model="form.message"
                placeholder="Promo Message"
                rows="3"
                max-rows="6"
            ></b-form-textarea>
        </b-col>

        <b-col cols="12">
            <label>Start Date</label>
            <b-form-datepicker v-model="form.startDate" class="mb-2"></b-form-datepicker>
        </b-col>

        <b-col cols="12">
            <label>End Date</label>
            <b-form-datepicker v-model="form.endDate" class="mb-2"></b-form-datepicker>
        </b-col>

        <b-col cols="12">
            <label>Extends Month</label>
            <b-form-input v-model="form.extendsMonth" placeholder="Extends Month"></b-form-input>
        </b-col>
        
        <b-col cols="12" class="mb-1">
            <label>PCode</label>
            <b-form-select
                v-model="selectedPCode"
                placeholder="----- Select PCode To Add -----"
                :options="listPCodes"
                show-empty>
            </b-form-select>
            <b-button class="mt-1" variant="outline-success" @click="doAddPCode">Add PCode</b-button>
        </b-col>

        <b-col cols="12" class="mb-1">
            <b-table
                :items="form.pCodes"
                :fields="pCodeFields"
                show-empty
            >

            <template #cell(actions)="row">
                <b-button variant="danger" @click="deletePCodeFromList(row.index)">
                    <feather-icon icon="Trash2Icon"/>
                </b-button>
            </template>

            </b-table>
        </b-col>

        <b-col cols="12">
            <b-button variant="success" @click="doUpdateCampaign">Update</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sleep } from '@/utils/utils.js'
export default {
    data() {
        return {
            form: {
                name: '',
                status: 'Active',
                message: '',
                startDate: null,
                endDate: null,
                extendsMonth: 0,
                pCodes: [],
            },
            statusChoice: [
                { value: 'Active', text: 'Active' },
                { value: 'Inactive', text: 'Inactive' },
            ],
            pCodeFields: [
                { key: 'pcode', label: 'PCode' },
                { key: 'actions', label: 'Action' }
            ],
            selectedPCode: null,
            pcodes: [],
        }
    },
    mounted() {
        this.getAllPCodes();
        this.getCampaign(this.$route.params.id).then((campaign) => {
            campaign.pCodes = campaign.pCodes.map((pcode) => {
                return { pcode: pcode }
            });

            this.form = campaign;
        })
    },
    methods: {
        ...mapActions({
            'getAllPCodes': 'pcode/getAllPCodes',
            'updateCampaign': 'campaign/updateCampaign',
            'getCampaign': 'campaign/getCampaign'
        }),
        doAddPCode() {
            
            if(this.selectedPCode===null) {
                return
            }
            let foundIndex = this.form.pCodes.findIndex((pcode) => (pcode.pcode === this.selectedPCode));
            
            if (foundIndex < 0) {
                let tempData = this.form.pCodes;
                tempData.push({ pcode: this.selectedPCode });
                this.form.pCodes = tempData;
            }
        },
        deletePCodeFromList(indexStart) {
            this.form.pCodes.splice(indexStart, 1)
        },
        doUpdateCampaign() {
            let deepCopy = JSON.parse(JSON.stringify(this.form));

            deepCopy.pCodes = deepCopy.pCodes.map((pcode) => {
                return pcode.pcode;
            });

            this.updateCampaign(deepCopy).then(async (response) => {
                this.$bvToast.toast(`You have successfully update a Campaign ${this.form.name}`, {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1000)
                this.$router.push({ name: "campaign" });
            }).catch((e) => {
                this.$bvToast.toast(`Failed to update (${this.form.name})`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
        }
    },
    computed: {
        ...mapState({
            listPCodes: (state) => {
                return state.pcode.pcodes.map((pcode) => {
                    return { value: pcode.name, text: pcode.name };
                });
            },
        })
    }
}
</script>
